import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../../data/dates';
import { TransferNoticeDetail_TransferNotice } from '../../../generated/graphql';
import DetailGridItem from '../../common/containers/DetailGridItem';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildLpPath } from '../clientPaths';
import { buildFundPath } from '../../funds/fundPaths';

export type TransferNoticeDetailData = TransferNoticeDetail_TransferNotice;

interface TransferNoticeDetailProps {
  transferNotice: TransferNoticeDetailData;
  onProcessTransferNotice?: (transferNoticeId: string) => void;
  processing?: boolean;
  submittedProcessing?: boolean;
}

const TransferNoticeDetail = ({
  transferNotice,
  onProcessTransferNotice,
  processing,
  submittedProcessing,
}: TransferNoticeDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>LP</DetailTitle>
              <DetailValue>
                <RouteLink path={buildLpPath(transferNotice.client.id)}>{transferNotice.client.fullName}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Fund</DetailTitle>
              <DetailValue>
                <RouteLink path={buildFundPath(transferNotice.fund.id)}>{transferNotice.fund.name}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Originating Account Number</DetailTitle>
              <DetailValue>{transferNotice.originatingAccountNumber}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={transferNotice.amount} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(transferNotice.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Processed</DetailTitle>
              <DetailValue>
                {transferNotice.processedAt ? (
                  formatDateTime(transferNotice.processedAt)
                ) : submittedProcessing ? (
                  'Marked Processed'
                ) : onProcessTransferNotice ? (
                  <LoaderButton
                    size="small"
                    onClick={() => onProcessTransferNotice(transferNotice.id)}
                    loading={processing}
                  >
                    Mark Processed
                  </LoaderButton>
                ) : null}
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Effective At</DetailTitle>
              <DetailValue>
                {transferNotice.effectiveAt ? formatDateTime(transferNotice.effectiveAt) : 'N/A'}
              </DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
    </>
  );
};
export default TransferNoticeDetail;

export const buildTransferNoticeTitle = (clientName?: string) =>
  clientName ? `${clientName} Transfer Notice` : 'Transfer Notice';

export const transferNoticeDetailFragment = gql`
  fragment TransferNoticeDetail_TransferNotice on TransferNotice {
    id
    client {
      id
      fullName
    }
    fund {
      id
      name
    }
    amount
    originatingAccountNumber
    createdAt
    processedAt
    effectiveAt
  }
`;
