import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildFundPath, FundsPageQueryParams } from '../../components/funds/fundPaths';
import FundsSection from '../../components/funds/FundsSection';
import { fundsTableRowFragment } from '../../components/funds/FundsTable';
import { useFundsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const FundsPage = () => {
  usePageTitle('Funds');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<FundsPageQueryParams>();

  const includeDeletedFlag = params.get('includeDeleted');
  const includeDeleted = includeDeletedFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = useFundsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeDeleted: includeDeleted || false },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleFundClicked = (fundId: string, newTab: boolean) => {
    navigateTo(buildFundPath(fundId), newTab);
  };

  const handleIncludeDeletedFundsChanged = (includeDeletedFunds: boolean) => {
    updateParam('includeDeleted', includeDeletedFunds ? 'true' : 'false');
  };

  return (
    <AppPage>
      <WidePageContainer>
        <FundsSection
          funds={data?.funds}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickFund={handleFundClicked}
          includeDeletedFunds={includeDeleted}
          onUpdateIncludeDeletedFunds={includeDeletedFlag !== null ? handleIncludeDeletedFundsChanged : undefined}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default FundsPage;

gql`
  query FundsPageQuery($includeDeleted: Boolean!) {
    funds(includeDeleted: $includeDeleted) {
      id
      ...FundsTableRow_Fund
    }
  }

  ${fundsTableRowFragment}
`;
