import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ApprovalDecisionPage from '../../../pages/approvals/ApprovalDecisionPage';
import ApprovalPage from '../../../pages/approvals/ApprovalPage';
import ApprovalsInProgressPage from '../../../pages/approvals/ApprovalsInProgressPage';
import ApprovalsPage from '../../../pages/approvals/ApprovalsPage';
import AuditActionPage from '../../../pages/auditActions/AuditActionPage';
import AuditActionsPage from '../../../pages/auditActions/AuditActionsPage';
import { BadRoute } from '../../../pages/BadRoute';
import AddClientPage from '../../../pages/clients/AddClientPage';
import ClientFundPage from '../../../pages/clients/ClientFundPage';
import ClientPage from '../../../pages/clients/ClientPage';
import ClientsPage from '../../../pages/clients/ClientsPage';
import FundClientsPage from '../../../pages/clients/FundClientsPage';
import DshStrategyAllocationsPage from '../../../pages/dsh/DshStrategyAllocationsPage';
import DshStrategyAssetPage from '../../../pages/dsh/DshStrategyAssetPage';
import DshStrategyPage from '../../../pages/dsh/DshStrategyPage';
import DshStrategyReportsPage from '../../../pages/dsh/DshStrategyReportsPage';
import FundPage from '../../../pages/funds/FundPage';
import FundsPage from '../../../pages/funds/FundsPage';
import AddInternalUserPage from '../../../pages/internalUsers/AddInternalUserPage';
import InternalUserPage from '../../../pages/internalUsers/InternalUserPage';
import InternalUsersPage from '../../../pages/internalUsers/InternalUsersPage';
import { Main } from '../../../pages/Main';
import OrderPage from '../../../pages/orders/OrderPage';
import OrdersPage from '../../../pages/orders/OrdersPage';
import AddPortfolioLedgerEventPage from '../../../pages/portfolios/AddPortfolioLedgerEventPage';
import PortfolioPage from '../../../pages/portfolios/PortfolioPage';
import PortfoliosPage from '../../../pages/portfolios/PortfoliosPage';
import FavoriteReportPage from '../../../pages/reports/FavoriteReportPage';
import ReportsPage from '../../../pages/reports/ReportsPage';
import AddStrategyLedgerEventPage from '../../../pages/strategies/AddStrategyLedgerEventPage';
import StrategiesPage from '../../../pages/strategies/StrategiesPage';
import StrategyPage from '../../../pages/strategies/StrategyPage';
import AddSubsystemPage from '../../../pages/subsystems/AddSubsystemPage';
import SubsystemPage from '../../../pages/subsystems/SubsystemPage';
import SubsystemsPage from '../../../pages/subsystems/SubsystemsPage';
import TradePage from '../../../pages/trades/TradePage';
import TradesPage from '../../../pages/trades/TradesPage';
import TransactionPage from '../../../pages/transactions/TransactionPage';
import TransactionsPage from '../../../pages/transactions/TransactionsPage';
import TransferNoticePage from '../../../pages/transferNotices/TransferNoticePage';
import ChangePasswordPage from '../../../pages/user/ChangePasswordPage';
import LoginPage from '../../../pages/user/LoginPage';
import {
  approvalsInProgressPath,
  approvalsPath,
  parameterizedApprovalDecisionPath,
  parameterizedApprovalPath,
} from '../../approvals/approvalPaths';
import { auditActionsPath, parameterizedAuditActionPath } from '../../auditActions/auditActionPaths';
import { parameterizedLpFundPath } from '../../clientFunds/clientFundPaths';
import { addLpPath, lpsPath, parameterizedLpPath } from '../../clients/clientPaths';
import { parameterizedFundClientsPath } from '../../clients/fundClientPaths';
import { parameterizedTransferNoticePath } from '../../clients/transfers/transferNoticePaths';
import {
  dshStrategyPath,
  parameterizedDshStrategyAllocationsPath,
  parameterizedDshStrategyAssetPath,
  parameterizedDshStrategyReportsPath,
} from '../../dsh/dshPaths';
import { fundsPath, parameterizedFundPath } from '../../funds/fundPaths';
import {
  addInternalUserPath,
  internalUsersPath,
  parameterizedInternalUserPath,
} from '../../internalUsers/internalUserPaths';
import { ordersPath, parameterizedOrderPath } from '../../orders/orderPaths';
import {
  parameterizedAddPortfolioLedgerEventPath,
  parameterizedPortfolioPath,
  portfoliosPath,
} from '../../portfolios/portfolioPaths';
import { parameterizedFavoriteReportPath, reportsPath } from '../../reports/reportPaths';
import {
  parameterizedAddStrategyLedgerEventPath,
  parameterizedStrategyPath,
  strategiesPath,
} from '../../strategies/strategyPaths';
import { addSubsystemPath, parameterizedSubsystemPath, subsystemsPath } from '../../subsystems/subsystemPaths';
import { parameterizedTradePath, tradesPath } from '../../trades/tradePaths';
import { parameterizedTransactionPath, transactionsPath } from '../../transactions/transactionPaths';
import Authenticated from './Authenticated';
import { changePasswordPath, homePath, loginPath } from './paths';
import Unauthenticated from './Unauthenticated';
import { parameterizedWorkflowPath, workflowsPath } from '../../workflows/workflowPaths';
import WorkflowsPage from '../../../pages/workflows/WorkflowsPage';
import WorkflowPage from '../../../pages/workflows/WorkflowPage';

const AppRoutes = () => (
  <Routes>
    {/* Home */}
    <Route
      path={homePath}
      element={
        <Authenticated>
          <Main />
        </Authenticated>
      }
    />

    {/* Audit Action */}
    <Route
      path={auditActionsPath}
      element={
        <Authenticated>
          <AuditActionsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedAuditActionPath}
      element={
        <Authenticated>
          <AuditActionPage />
        </Authenticated>
      }
    />

    {/* Approvals */}
    <Route
      path={approvalsPath}
      element={
        <Authenticated>
          <ApprovalsPage />
        </Authenticated>
      }
    />
    <Route
      path={approvalsInProgressPath}
      element={
        <Authenticated>
          <ApprovalsInProgressPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedApprovalPath}
      element={
        <Authenticated>
          <ApprovalPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedApprovalDecisionPath}
      element={
        <Authenticated>
          <ApprovalDecisionPage />
        </Authenticated>
      }
    />

    {/* Clients */}
    <Route
      path={lpsPath}
      element={
        <Authenticated>
          <ClientsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedFundClientsPath}
      element={
        <Authenticated>
          <FundClientsPage />
        </Authenticated>
      }
    />
    <Route
      path={addLpPath}
      element={
        <Authenticated>
          <AddClientPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedLpPath}
      element={
        <Authenticated>
          <ClientPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedLpFundPath}
      element={
        <Authenticated>
          <ClientFundPage />
        </Authenticated>
      }
    />

    {/* DSH Strategy */}
    <Route
      path={dshStrategyPath}
      element={
        <Authenticated>
          <DshStrategyPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedDshStrategyAllocationsPath}
      element={
        <Authenticated>
          <DshStrategyAllocationsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedDshStrategyReportsPath}
      element={
        <Authenticated>
          <DshStrategyReportsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedDshStrategyAssetPath}
      element={
        <Authenticated>
          <DshStrategyAssetPage />
        </Authenticated>
      }
    />

    {/* Funds */}
    <Route
      path={fundsPath}
      element={
        <Authenticated>
          <FundsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedFundPath}
      element={
        <Authenticated>
          <FundPage />
        </Authenticated>
      }
    />

    {/* Internal Users */}
    <Route
      path={internalUsersPath}
      element={
        <Authenticated>
          <InternalUsersPage />
        </Authenticated>
      }
    />
    <Route
      path={addInternalUserPath}
      element={
        <Authenticated>
          <AddInternalUserPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedInternalUserPath}
      element={
        <Authenticated>
          <InternalUserPage />
        </Authenticated>
      }
    />

    {/* Orders */}
    <Route
      path={ordersPath}
      element={
        <Authenticated>
          <OrdersPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedOrderPath}
      element={
        <Authenticated>
          <OrderPage />
        </Authenticated>
      }
    />

    {/* Portfolios */}
    <Route
      path={portfoliosPath}
      element={
        <Authenticated>
          <PortfoliosPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedPortfolioPath}
      element={
        <Authenticated>
          <PortfolioPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedAddPortfolioLedgerEventPath}
      element={
        <Authenticated>
          <AddPortfolioLedgerEventPage />
        </Authenticated>
      }
    />

    {/* Strategies */}
    <Route
      path={strategiesPath}
      element={
        <Authenticated>
          <StrategiesPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedStrategyPath}
      element={
        <Authenticated>
          <StrategyPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedAddStrategyLedgerEventPath}
      element={
        <Authenticated>
          <AddStrategyLedgerEventPage />
        </Authenticated>
      }
    />

    {/* Reports */}
    <Route
      path={reportsPath}
      element={
        <Authenticated>
          <ReportsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedFavoriteReportPath}
      element={
        <Authenticated>
          <FavoriteReportPage />
        </Authenticated>
      }
    />

    {/* Subsystems */}
    <Route
      path={subsystemsPath}
      element={
        <Authenticated>
          <SubsystemsPage />
        </Authenticated>
      }
    />
    <Route
      path={addSubsystemPath}
      element={
        <Authenticated>
          <AddSubsystemPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedSubsystemPath}
      element={
        <Authenticated>
          <SubsystemPage />
        </Authenticated>
      }
    />

    {/* Trades */}
    <Route
      path={tradesPath}
      element={
        <Authenticated>
          <TradesPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedTradePath}
      element={
        <Authenticated>
          <TradePage />
        </Authenticated>
      }
    />

    {/* Transactions */}
    <Route
      path={transactionsPath}
      element={
        <Authenticated>
          <TransactionsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedTransactionPath}
      element={
        <Authenticated>
          <TransactionPage />
        </Authenticated>
      }
    />

    {/* Transfer Notice */}
    <Route
      path={parameterizedTransferNoticePath}
      element={
        <Authenticated>
          <TransferNoticePage />
        </Authenticated>
      }
    />

    {/* Workflows */}
    <Route
      path={workflowsPath}
      element={
        <Authenticated>
          <WorkflowsPage />
        </Authenticated>
      }
    />
    <Route
      path={parameterizedWorkflowPath}
      element={
        <Authenticated>
          <WorkflowPage />
        </Authenticated>
      }
    />

    {/* Login */}
    <Route
      path={loginPath}
      element={
        <Unauthenticated>
          <LoginPage />
        </Unauthenticated>
      }
    />

    {/* Change Password */}
    <Route
      path={changePasswordPath}
      element={
        <Authenticated>
          <ChangePasswordPage />
        </Authenticated>
      }
    />

    {/* Not Found */}
    <Route path="*" element={<BadRoute />} />
  </Routes>
);
export default AppRoutes;
