import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { ClientFundsTableRow_ClientFund } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import ClientFundsTable, { ClientFundClickEventHandler } from './ClientFundsTable';

export interface ClientFundsSectionProps extends RefreshingComponentProps {
  clientFunds?: readonly ClientFundsTableRow_ClientFund[];
  hideClient?: boolean;
  hideFund?: boolean;
  title?: string;
  onClickClientFund?: ClientFundClickEventHandler;
}

const ClientFundsSection = ({
  clientFunds,
  title,
  hideClient,
  hideFund,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickClientFund,
}: ClientFundsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>
        {title ? title : hideClient && hideFund ? 'LP Funds' : hideClient ? 'Funds' : hideFund ? 'LPs' : 'LP Funds'}
      </SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {clientFunds ? (
      <ClientFundsTable
        clientFunds={clientFunds}
        hideClient={hideClient}
        hideFund={hideFund}
        onClickClientFundRow={onClickClientFund}
      />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default ClientFundsSection;
