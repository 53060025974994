import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import NavItem from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import ApprovalIcon from '../../../approvals/ApprovalIcon';
import {
  approvalsInProgressPath,
  approvalsPath,
  parameterizedApprovalDecisionPath,
  parameterizedApprovalPath,
} from '../../../approvals/approvalPaths';
import AuditActionIcon from '../../../auditActions/AuditActionIcon';
import AuditActionNavItem from '../../../auditActions/AuditActionNavItem';
import { auditActionsPath, parameterizedAuditActionPath } from '../../../auditActions/auditActionPaths';
import ClientFundNavItem from '../../../clientFunds/ClientFundNavItem';
import { parameterizedAddLpFundPath, parameterizedLpFundPath } from '../../../clientFunds/clientFundPaths';
import ClientIcon from '../../../clients/ClientIcon';
import ClientNavItem from '../../../clients/ClientNavItem';
import { addLpPath, lpsPath, parameterizedLpPath } from '../../../clients/clientPaths';
import FundClientsNavItems from '../../../clients/FundClientsNavItems';
import TransferNoticeIcon from '../../../clients/transfers/TransferNoticeIcon';
import { parameterizedTransferNoticePath } from '../../../clients/transfers/transferNoticePaths';
import {
  dshStrategyPath,
  parameterizedDshStrategyAllocationsPath,
  parameterizedDshStrategyAssetPath,
  parameterizedDshStrategyReportsPath,
} from '../../../dsh/dshPaths';
import DshStrategyAssetNavItem from '../../../dsh/DshStrategyAssetNavItem';
import FundIcon from '../../../funds/FundIcon';
import FundNavItems from '../../../funds/FundNavItems';
import { fundsPath } from '../../../funds/fundPaths';
import InternalUserIcon from '../../../internalUsers/InternalUserIcon';
import InternalUserNavItem from '../../../internalUsers/InternalUserNavItem';
import {
  addInternalUserPath,
  internalUsersPath,
  parameterizedInternalUserPath,
} from '../../../internalUsers/internalUserPaths';
import OrderIcon from '../../../orders/OrderIcon';
import { ordersPath, parameterizedOrderPath } from '../../../orders/orderPaths';
import AddPortfolioLedgerEventNavItem from '../../../portfolios/ledgerEvents/AddPortfolioLedgerEventNavItem';
import PortfolioIcon from '../../../portfolios/PortfolioIcon';
import PortfolioNavItem from '../../../portfolios/PortfolioNavItem';
import {
  parameterizedAddPortfolioLedgerEventPath,
  parameterizedPortfolioPath,
  portfoliosPath,
} from '../../../portfolios/portfolioPaths';
import FavoriteReportNavItems from '../../../reports/FavoriteReportNavItems';
import { reportsPath } from '../../../reports/reportPaths';
import AddStrategyLedgerEventNavItem from '../../../strategies/ledgerEvents/AddStrategyLedgerEventNavItem';
import StrategyIcon from '../../../strategies/StrategyIcon';
import StrategyNavItems from '../../../strategies/StrategyNavItems';
import { parameterizedAddStrategyLedgerEventPath, strategiesPath } from '../../../strategies/strategyPaths';
import SubsystemIcon from '../../../subsystems/SubsystemIcon';
import SubsystemNavItem from '../../../subsystems/SubsystemNavItem';
import { addSubsystemPath, parameterizedSubsystemPath, subsystemsPath } from '../../../subsystems/subsystemPaths';
import TradeIcon from '../../../trades/TradeIcon';
import { parameterizedTradePath, tradesPath } from '../../../trades/tradePaths';
import TransactionIcon from '../../../transactions/TransactionIcon';
import { parameterizedTransactionPath, transactionsPath } from '../../../transactions/transactionPaths';
import WorkflowIcon from '../../../workflows/WorkflowIcon';
import { parameterizedWorkflowPath, workflowsPath } from '../../../workflows/workflowPaths';
import DashboardIcon from '../../icons/DashboardIcon';
import ReportIcon from '../../icons/ReportIcon';
import { homePath } from '../../routes/paths';
import WiderClippedDrawer from './WiderClippedDrawer';

interface AppNavProps {
  disabled?: boolean;
}

const AppNav = ({ disabled }: AppNavProps) => (
  <WiderClippedDrawer data-qa="app-nav">
    <List component="nav">
      <NavItem title="Dashboard" icon={DashboardIcon} path={homePath} disabled={disabled} />

      {/* Reports */}
      <NavItem title="Reports" icon={ReportIcon} path={reportsPath} disabled={disabled} />
      <FavoriteReportNavItems disabled={disabled} />

      <Divider />

      {/* Funds */}
      <NavItem title="Funds" icon={FundIcon} path={fundsPath} disabled={disabled} />
      <FundNavItems disabled={disabled} />

      {/* Clients */}
      <NavItem title="LPs" icon={ClientIcon} path={lpsPath} disabled={disabled} />
      <FundClientsNavItems disabled={disabled} />
      <NavItem title="Add LP" icon={ClientIcon} path={addLpPath} disabled={disabled} child hideInactive />
      <ClientNavItem
        icon={ClientIcon}
        path={parameterizedLpPath}
        notPath={addLpPath}
        disabled={disabled}
        child
        hideInactive
      />
      <ClientFundNavItem
        icon={ClientIcon}
        path={parameterizedLpFundPath}
        notPath={parameterizedAddLpFundPath}
        disabled={disabled}
        child
        hideInactive
      />
      <NavItem
        title="Transfer Notice"
        icon={TransferNoticeIcon}
        path={parameterizedTransferNoticePath}
        disabled={disabled}
        child
        hideInactive
      />

      <Divider />

      {/* DSH */}
      <NavItem title="DSH Admin" icon={StrategyIcon} path={dshStrategyPath} disabled={disabled} />
      <NavItem
        title="Reports"
        icon={StrategyIcon}
        path={parameterizedDshStrategyReportsPath}
        child
        hideInactive
        disabled={disabled}
      />
      <NavItem
        title="Allocations"
        icon={StrategyIcon}
        path={parameterizedDshStrategyAllocationsPath}
        child
        hideInactive
        disabled={disabled}
      />
      <DshStrategyAssetNavItem
        icon={StrategyIcon}
        path={parameterizedDshStrategyAssetPath}
        child
        hideInactive
        disabled={disabled}
      />

      {/* Strategies */}
      <NavItem title="Strategies" icon={StrategyIcon} path={strategiesPath} disabled={disabled} />
      <StrategyNavItems disabled={disabled} />
      <AddStrategyLedgerEventNavItem
        icon={StrategyIcon}
        path={parameterizedAddStrategyLedgerEventPath}
        disabled={disabled}
        child
        hideInactive
      />

      {/* Portfolios */}
      <NavItem title="Portfolios" icon={PortfolioIcon} path={portfoliosPath} disabled={disabled} />
      <PortfolioNavItem icon={PortfolioIcon} path={parameterizedPortfolioPath} disabled={disabled} child hideInactive />
      <AddPortfolioLedgerEventNavItem
        icon={PortfolioIcon}
        path={parameterizedAddPortfolioLedgerEventPath}
        disabled={disabled}
        child
        hideInactive
      />

      <Divider />

      {/* Orders */}
      <NavItem title="Orders" icon={OrderIcon} path={ordersPath} disabled={disabled} />
      <NavItem title="Order" icon={OrderIcon} path={parameterizedOrderPath} disabled={disabled} child hideInactive />

      {/* Trades */}
      <NavItem title="Trades" icon={TradeIcon} path={tradesPath} disabled={disabled} />
      <NavItem title="Trade" icon={TradeIcon} path={parameterizedTradePath} disabled={disabled} child hideInactive />

      {/* Transactions */}
      <NavItem title="Transactions" icon={TransactionIcon} path={transactionsPath} disabled={disabled} />
      <NavItem
        title="Transaction"
        icon={TransactionIcon}
        path={parameterizedTransactionPath}
        disabled={disabled}
        child
        hideInactive
      />

      <Divider />

      {/* Internal Users */}
      <NavItem title="Admin Users" icon={InternalUserIcon} path={internalUsersPath} disabled={disabled} />
      <NavItem
        title="Add Admin User"
        icon={InternalUserIcon}
        path={addInternalUserPath}
        disabled={disabled}
        child
        hideInactive
      />
      <InternalUserNavItem
        icon={InternalUserIcon}
        path={parameterizedInternalUserPath}
        notPath={addInternalUserPath}
        disabled={disabled}
        child
        hideInactive
      />

      {/* Subsystems */}
      <NavItem title="Subsystems" icon={SubsystemIcon} path={subsystemsPath} disabled={disabled} />
      <NavItem
        title="Add Subsystem"
        icon={SubsystemIcon}
        path={addSubsystemPath}
        disabled={disabled}
        child
        hideInactive
      />
      <SubsystemNavItem
        icon={SubsystemIcon}
        path={parameterizedSubsystemPath}
        notPath={addSubsystemPath}
        disabled={disabled}
        child
        hideInactive
      />

      {/* Audit Actions */}
      <NavItem title="Activity" icon={AuditActionIcon} path={auditActionsPath} disabled={disabled} />
      <AuditActionNavItem
        icon={AuditActionIcon}
        path={parameterizedAuditActionPath}
        disabled={disabled}
        child
        hideInactive
      />

      {/* Workflows */}
      <NavItem title="Workflows" icon={WorkflowIcon} path={workflowsPath} disabled={disabled} />
      <NavItem
        title="Workflow"
        icon={WorkflowIcon}
        path={parameterizedWorkflowPath}
        disabled={disabled}
        child
        hideInactive
      />

      {/* Approvals */}
      <NavItem title="Approvals" icon={ApprovalIcon} path={approvalsPath} disabled={disabled} />
      <NavItem title="In Progress" icon={ApprovalIcon} path={approvalsInProgressPath} disabled={disabled} child />
      <NavItem
        title="Approval"
        icon={ApprovalIcon}
        path={parameterizedApprovalPath}
        disabled={disabled}
        notPath={approvalsInProgressPath}
        child
        hideInactive
      />
      <NavItem
        title="Approve or Reject"
        icon={ApprovalIcon}
        path={parameterizedApprovalDecisionPath}
        disabled={disabled}
        child
        hideInactive
      />
    </List>
  </WiderClippedDrawer>
);
export default AppNav;
