import { gql } from '@apollo/client';
import NavItem from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import { useFundClientsNavItemsQuery } from '../../generated/graphql';
import ClientIcon from './ClientIcon';
import { buildFundClientsPath } from './fundClientPaths';

export interface FundNavItemsProps {
  disabled?: boolean;
}

const FundClientsNavItems = ({ disabled }: FundNavItemsProps) => {
  const { data } = useFundClientsNavItemsQuery();

  return (
    <>
      {data?.funds.map(({ id: fundId, name }) => (
        <NavItem
          key={fundId}
          title={name}
          icon={ClientIcon}
          path={buildFundClientsPath(fundId)}
          disabled={disabled}
          child
        />
      ))}
    </>
  );
};
export default FundClientsNavItems;

gql`
  query FundClientsNavItemsQuery {
    funds {
      id
      name
    }
  }
`;
