import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { buildLpFundPath } from '../../components/clientFunds/clientFundPaths';
import { buildTransferNoticePath } from '../../components/clients/transfers/transferNoticePaths';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import FundSection, { fundSectionFragment } from '../../components/funds/FundSection';
import { FundPageParams } from '../../components/funds/fundPaths';
import { buildFundTitle } from '../../components/funds/fundUtils';
import { buildPortfolioPath } from '../../components/portfolios/portfolioPaths';
import { useFundPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const FundPage = () => {
  const { fundId } = usePageParams<keyof FundPageParams>();
  const { navigateTo } = usePaths();

  const { data, error, refetch, networkStatus, loading } = useFundPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { fundId },
  });

  usePageTitle(`Fund - ${buildFundTitle(data?.fund?.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const fund = data?.fund;

  if (fund === null) {
    return <AppPageMessage text={`Fund ${fundId} does not exist`} />;
  }

  const handlePortfolioClicked = (portfolioId: string, newTab: boolean) => {
    navigateTo(buildPortfolioPath(portfolioId), newTab);
  };

  const handleClientFundClicked = (clientId: string, fundId: string, newTab: boolean) => {
    navigateTo(buildLpFundPath(clientId, fundId), newTab);
  };

  const handleTransferNoticeClicked = (transferNoticeId: string, newTab: boolean) => {
    navigateTo(buildTransferNoticePath(transferNoticeId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <FundSection
          fund={fund}
          onClickPortfolio={handlePortfolioClicked}
          onClickClientFund={handleClientFundClicked}
          onClickTransferNotice={handleTransferNoticeClicked}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default FundPage;

gql`
  query FundPageQuery($fundId: ID!) {
    fund(id: $fundId) {
      id
      name
      ...FundSection_Fund
    }
  }

  ${fundSectionFragment}
`;
