import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { buildLpFundPath } from '../../components/clientFunds/clientFundPaths';
import ClientFundsSection from '../../components/clientFunds/ClientFundsSection';
import { clientFundsTableRowFragment } from '../../components/clientFunds/ClientFundsTable';
import { FundClientsPageParams } from '../../components/clients/fundClientPaths';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { buildFundTitle } from '../../components/funds/fundUtils';
import { useFundClientsPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const FundClientsPage = () => {
  const { fundId } = usePageParams<keyof FundClientsPageParams>();
  const { navigateTo } = usePaths();

  const { data, error, refetch, networkStatus, loading } = useFundClientsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { fundId },
  });

  const fundTitle = `${buildFundTitle(data?.fund?.name)}`;
  usePageTitle(`Fund LPs - ${fundTitle}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleClientFundClicked = (clientId: string, fundId: string, newTab: boolean) => {
    navigateTo(buildLpFundPath(clientId, fundId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <ClientFundsSection
          clientFunds={data?.fund?.clients}
          title={`${fundTitle} LPs`}
          hideFund
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickClientFund={handleClientFundClicked}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default FundClientsPage;

gql`
  query FundClientsPageQuery($fundId: ID!) {
    fund(id: $fundId) {
      id
      name

      clients {
        id
        ...ClientFundsTableRow_ClientFund
      }
    }
  }

  ${clientFundsTableRowFragment}
`;
