import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useState } from 'react';
import { PortfolioDetail_Portfolio } from '../../generated/graphql';
import DetailGridItem from '../common/containers/DetailGridItem';
import ExplodingContainer from '../common/containers/ExplodingContainer';
import { buildExchangeDisplay } from '../common/model/exchange';
import { buildTimeframeDisplay } from '../common/model/timeframe';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildFundPath } from '../funds/fundPaths';
import { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsTable';
import { OrderClickEventHandler } from '../orders/OrdersTable';
import FinanceStatsDetail, { financeStatsDetailFragment } from '../stats/FinanceStatsDetail';
import { buildStrategyPath } from '../strategies/strategyPaths';
import { TradeClickEventHandler } from '../trades/TradesTable';
import UpdatePortfolioManagedCurrencyDialog, {
  SubmitPortfolioManagedCurrencyUpdateEventHandler,
  updatePortfolioManagedCurrencyDialogFragment,
} from './managedCurrency/UpdatePortfolioManagedCurrencyDialog';
import PortfolioAssetsSectionIntegration from './PortfolioAssetsSectionIntegration';
import { PortfolioAssetClickEventHandler } from './PortfolioAssetsTable';
import PortfolioDeletedNote, { portfolioDeletedNoteFragment } from './PortfolioDeletedNote';
import PortfolioLedgerEventsSectionIntegration from './PortfolioLedgerEventsSectionIntegration';
import PortfolioOrdersSectionIntegration from './PortfolioOrdersSectionIntegration';
import PortfolioReport from './PortfolioReport';
import PortfolioTradesSectionIntegration from './PortfolioTradesSectionIntegration';

export type PortfolioDetailData = PortfolioDetail_Portfolio;

interface PortfolioDetailProps {
  portfolio: PortfolioDetailData;
  onClickPortfolioAsset?: PortfolioAssetClickEventHandler;
  onClickOrder?: OrderClickEventHandler;
  onClickTrade?: TradeClickEventHandler;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  onSubmitPortfolioManagedCurrencyUpdate?: SubmitPortfolioManagedCurrencyUpdateEventHandler;
  submittingPortfolioManagedCurrencyUpdate?: boolean;
  includeDeletedAssets?: boolean;
  onUpdateIncludeDeletedAssets?: (includeDeletedAssets: boolean) => void;
  autoRefresh?: boolean | number;
}

const PortfolioDetail = ({
  portfolio,
  onClickPortfolioAsset,
  onClickOrder,
  onClickTrade,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  onSubmitPortfolioManagedCurrencyUpdate,
  submittingPortfolioManagedCurrencyUpdate,
  includeDeletedAssets,
  onUpdateIncludeDeletedAssets,
  autoRefresh,
}: PortfolioDetailProps) => {
  const [updatePortfolioManagedCurrencyDialogOpen, setUpdatePortfolioManagedCurrencyDialogOpen] = useState(false);

  const openUpdatePortfolioManagedCurrencyDialog = () => {
    setUpdatePortfolioManagedCurrencyDialogOpen(true);
  };

  const closeUpdatePortfolioManagedCurrencyDialog = () => {
    setUpdatePortfolioManagedCurrencyDialogOpen(false);
  };

  const handleUpdatePortfolioManagedCurrency = async (portfolioId: string, newManagedCurrencyAmount: number) => {
    if (!onSubmitPortfolioManagedCurrencyUpdate) {
      return;
    }

    await onSubmitPortfolioManagedCurrencyUpdate(portfolioId, newManagedCurrencyAmount);
    closeUpdatePortfolioManagedCurrencyDialog();
  };

  return (
    <>
      <DetailContainer>
        <PortfolioDeletedNote portfolio={portfolio} />

        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem size="full">
            <DetailField>
              <DetailTitle>Performance</DetailTitle>
              <PortfolioReport portfolioId={portfolio.id} />
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Strategy</DetailTitle>
              <DetailValue>
                <RouteLink path={buildStrategyPath(portfolio.strategy.id)}>{portfolio.strategy.name}</RouteLink>
              </DetailValue>
            </DetailField>
            <DetailField>
              <DetailTitle>Fund</DetailTitle>
              <DetailValue>
                <RouteLink path={buildFundPath(portfolio.fund.id)}>{portfolio.fund.name}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Allocated Funds</DetailTitle>
              <DetailValue>
                <UsdFormat amount={portfolio.managedCurrencyAmount} />
              </DetailValue>
              <DetailValue>
                <Button onClick={openUpdatePortfolioManagedCurrencyDialog} size="small" color="inherit">
                  Update
                </Button>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Deployment</DetailTitle>
              <DetailValue>
                <UsdFormat amount={portfolio.filledCurrencyAmount} /> (
                <PercentFormat amount={portfolio.filledPercent} />)
              </DetailValue>
              <DetailValue variant="body2">
                <UsdFormat amount={portfolio.availableCurrencyAmount} /> undeployed
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Net Exposure</DetailTitle>
              <DetailValue>
                <PercentFormat amount={portfolio.stats.netExposure} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Exchanges</DetailTitle>
              {portfolio.exchanges?.map((exchange) => (
                <DetailValue key={exchange}>{buildExchangeDisplay(exchange)}</DetailValue>
              ))}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Default Timeframe</DetailTitle>
              <DetailValue>{buildTimeframeDisplay(portfolio.timeframe)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>All Time PnL</DetailTitle>
              <DetailValue>
                <UsdFormat amount={portfolio.stats.pnlCumulativeValue} />
                <br />
                <PercentFormat amount={portfolio.stats.pnlCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Realized Gains/Losses</DetailTitle>
              <DetailValue>
                <UsdFormat amount={portfolio.stats.realizedGainsCumulativeValue} />
                <br />
                <PercentFormat amount={portfolio.stats.realizedGainsCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Unrealized Gains/Losses</DetailTitle>
              <DetailValue>
                <UsdFormat amount={portfolio.stats.unrealizedGainsCumulativeValue} />
                <br />
                <PercentFormat amount={portfolio.stats.unrealizedGainsCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem size="half">
            <FinanceStatsDetail stats={portfolio.stats} />
          </DetailGridItem>
        </Grid>
      </DetailContainer>

      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <PortfolioAssetsSectionIntegration
            portfolioId={portfolio.id}
            onClickPortfolioAsset={onClickPortfolioAsset}
            includeDeletedAssets={includeDeletedAssets}
            onUpdateIncludeDeletedAssets={onUpdateIncludeDeletedAssets}
            defaultExpanded
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />

      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <PortfolioOrdersSectionIntegration
            portfolioId={portfolio.id}
            onClickOrder={onClickOrder}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />

      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <PortfolioTradesSectionIntegration
            portfolioId={portfolio.id}
            assets={portfolio.assets.map((asset) => asset.asset)}
            onClickTrade={onClickTrade}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />

      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <PortfolioLedgerEventsSectionIntegration
            portfolioId={portfolio.id}
            onClickLedgerEvent={onClickLedgerEvent}
            onClickAddLedgerEvent={onClickAddLedgerEvent}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />

      {onSubmitPortfolioManagedCurrencyUpdate ? (
        <UpdatePortfolioManagedCurrencyDialog
          open={submittingPortfolioManagedCurrencyUpdate || updatePortfolioManagedCurrencyDialogOpen}
          portfolio={portfolio}
          onSubmitPortfolioManagedCurrencyUpdate={handleUpdatePortfolioManagedCurrency}
          submitting={submittingPortfolioManagedCurrencyUpdate}
          onClose={closeUpdatePortfolioManagedCurrencyDialog}
        />
      ) : null}
    </>
  );
};
export default PortfolioDetail;

export const buildPortfolioTitle = (name?: string) => (name ? name : 'Portfolio');

export const portfolioDetailFragment = gql`
  fragment PortfolioDetail_Portfolio on Portfolio {
    id
    name

    fund {
      id
      name
    }

    strategy {
      id
      name
    }

    managedCurrencyAmount
    availableCurrencyAmount
    desiredCurrencyAmount
    filledCurrencyAmount
    filledPercent
    timeframe
    exchanges
    createdAt

    assets {
      id
      asset
    }

    stats {
      netExposure
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
      ...FinanceStatsDetail_Stats
    }

    ...PortfolioDeletedNote_Portfolio
    ...UpdatePortfolioManagedCurrencyDialogPortfolioDetails
  }

  ${portfolioDeletedNoteFragment}
  ${financeStatsDetailFragment}
  ${updatePortfolioManagedCurrencyDialogFragment}
`;
